<template>
    <div class="moments-text-box" id="moments-text-box">
        <title-nav :title="'朋友圈文案'"/>
        <div class="moments">
          <div class="text" v-for="(list, i) in momentsList" :key="i"  @click="copyToClipboard(list.text)">{{ list.text }}</div>
        </div>
    </div>
</template>

<script>
import TitleNav from '../../components/nav/TitleNav.vue'
import {Toast} from 'vant'

export default {
  name: 'MomentsText',
  components: {
    TitleNav,
  },
  created(){
  },
  mounted(){
  },
  data:()=>{
    return {
      momentsList:[{
        text: "亲爱的朋友们，你们有没有经历过忙碌了一整天，回到家还要苦想一个网站的文案怎么写？或者是整天想着一个好的营销方案，但却无从下手？别担心，我们为你们提供了一种解决方案——我们的内同生成服务平台！作为一家专业的内向营销服务商，我们致力于为你们提供高效、便捷、优质的内容服务。无论是撰写各种软文、公众号推文、新闻稿、还是进行品牌宣传和推广，我们都可以轻松应对。不仅如此，我们还为会员提供了一系列实用的工具和素材，让你们的工作变得更加轻松愉快。现在，我们为大家推出开通会员的特别优惠活动！只需注销会员账号，就可以享受超值的优惠价格，同时还能获得更多的高品质服务和专业指导。那么，还在等什么呢？快来成为我们的会员，享受无限畅想的创作乐趣吧！"
      },{
        text: "6月18日，618大促又来啦！🎉现在，我们的会员优惠活动更加丰富，为您的业务增加更多的价值，助您创造更大的商业价值！在此，我们向广大用户都提供一项超值福利——开通我们的会员，不仅可领取超值优惠券，也可享受全场产品95折优惠！😎而您所追求的内容生成服务，只有我们能够为您提供最完善的解决方案。我们致力于提供最优质的平台服务，让您的内容更高效，更精准，更有价值！快来加入我们的会员，让我们一起促销6月18日，创造更多的商业价值！👏立即开通会员，让我们迎接618大促的来临！"
      }]
    }
  },
  methods:{
    // 复制到剪切板
    copyToClipboard(text) {
      const textarea = document.createElement("textarea"); // 创建一个textarea元素
      textarea.value = text; // 设置textarea的值
      document.body.appendChild(textarea); // 将textarea添加到DOM中
      textarea.select(); // 选中textarea中的文本
      document.execCommand("copy"); // 将选中的文本复制到剪贴板中
      document.body.removeChild(textarea); // 从DOM中移除textarea
      Toast('已复制');
    },
  },
  activated(){

  }
}
</script>

<style scoped lang="scss">
.moments-text-box {
    min-height: calc(100vh - 20px);
    padding: 0px 0 20px;
    background: #fff;
    .moments {
      margin: 0px 20px;
      .text {
        margin: 20px 0 0;
        padding: 10px;
        text-align: left;
        background: #faecd8;
      }
    }
}
</style>